@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "NTR", sans-serif !important;
}

:root {
  --dark-navy: #020c1b;
  --navy: #0a192f;
  --light-navy: #112240;
  --lightest-navy: #233554;
  --navy-shadow: rgba(2, 12, 27, 0.7);
  --slate: #8892b0;
  --light-slate: #a8b2d1;
  --lightest-slate: #ccd6f6;
  --white: #e6f1ff;
  --green-bright: #64ffda;
  --green-tint: rgba(100, 255, 218, 0.1);
  --super-yellow: rgba(255, 255, 0, 1);
  --text-custom-blue: #4f46e5;
}


@layer components {

  .sidebar-icon {
    @apply relative flex items-center justify-center h-12 w-12 md:h-10 md:w-10 mt-2 mb-2 mx-auto shadow-lg bg-white text-gray-700 hover:bg-gray-700 hover:text-white rounded-3xl hover:rounded-xl transition-all duration-300 ease-linear cursor-pointer hover:animate-text hover:bg-gradient-to-r from-pink-400 via-blue-500 to-teal-500;
  }

  .sidebar-tooltip {
    @apply absolute w-auto p-2 m-2 min-w-max left-14 rounded-md shadow-md  bg-gray-600 text-xs font-bold transition-all duration-100 scale-0 origin-left ;
    color:rgba(255, 255, 0, 1);
  }


  .glow-line {
    position: relative;
    top: 100px;
    left: 50%;
    width: 2px; /* Adjust thickness */
    height: 100vh;
    background: linear-gradient(to bottom, rgba(255, 255, 0, 0.5), rgba(0, 255, 150, 0));
  }
  
  .glow-circle {
    position: relative;
    top: 350px;
    /* bottom: 20px; */
    left: 50%;
    width: 15px; /* Adjust size */
    height: 15px;
    background-color: rgba(255, 255, 0, 1);
    border-radius: 50%;
    box-shadow: 0 0 20px 10px rgba(255, 255, 0, 0.5);
    transform: translateX(-50%);
  }

  .custom-text-color {
    color:rgba(255, 255, 0, 1);
    text-shadow: -18px -18px -18px rgb(16, 16, 16, 0.5);
  }

  .custom-text-color-blue {
    text-shadow: -2px -2px 5px rgba(16, 16, 16, 0.5);
    color: #4f46e5;
    background: linear-gradient(to right, #4f46e5, #7c3aed);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .custom-text-color-teal {
    position: relative; /* Ensure the pseudo-element is positioned relative to the icon */
    color: #10B981 !important;
    text-shadow: 2px 2px 2px rgba(244, 247, 249, 0.5);
  }

  .custom-text-color-purple {
    color: #8b5cf6;
    text-shadow: -18px -18px -18px rgb(16, 16, 16, 0.5);
  }
  
  .bg-custom-important {
    background-color: rgba(0, 0, 0, 1) !important;
  }

  .important-inner-ref {
    position: relative;
    left: 0 !important;
    top: 0 !important;
    text-align: left !important;
  }

  .super-important-text-right {
    text-align: right !important;
    align-content: right !important;
    justify-content: right !important;
    margin-left: auto !important;
    text-shadow: -18px -18px -18px rgb(16, 16, 16, 0.5);
  }

  .custom-text-color-teal::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 150%; /* Adjust the size as needed */
    height: 150%; /* Adjust the size as needed */
    background: rgba(19, 190, 133, 0.2); /* Adjust the color and opacity as needed */
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: -1; /* Ensure the circle is behind the icon */
  }

  .custom-text-color-experience {
    @apply text-gray-100;
    text-shadow: -18px -18px -18px rgb(16, 16, 16, 0.5);
  }
  
  .header-text {
    /* @apply text-gray-300; */
    font-size: 38px;
    font-weight: bold;
    /* color: white;
    text-shadow: 10px 10px 10px black, -1px -1px 20px black; */
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5));
    background: linear-gradient(360deg, black, gray 30%, white );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* center text */
    text-align: center;
  }

  .center-div {
    text-align: center;
    justify-self: center;
    align-self: center;
    /* align-items: center; */
  }

  .picture-hover:hover {
    background-color: rgba(255, 255, 0, 0.1);
    box-shadow: 0 8px 16px rgba(255, 255, 0, 0.7); /* Same shadow effect */
    transition: background-color 0.3s ease, box-shadow 0.3s ease, background-color 1 ease; /* Smooth transition */
    border-radius: 23px; 
  }

  .bigger-size-text {
    font-size: 28px;
    text-shadow: -18px -18px -18px rgb(16, 16, 16, 0.5); 
  }

  .content-container {
    @apply flex flex-col 
    bg-black bg-opacity-100
    /* ml-20   */
    h-full min-h-full
    overflow-hidden
    mt-9; 
    /* center */
    /* items-center */
    /* justify-center */
    text-align: center;
  }

  .super-important-center {
    @apply
    /* center */
    items-center
    justify-center;
    text-align: center!important;
  }

  .content-list {
    @apply flex flex-col items-center 
    /* h-full w-full  */
    mt-0 ml-0 mx-auto px-0 pb-12 
    overflow-y-scroll;
  }

  .content-wrapper {
    @apply  mx-auto px-5 w-fit text-gray-300 text-left justify-evenly ;
    text-shadow: -18px -18px -18px rgb(16, 16, 16, 0.5);
    z-index: 1;
  }

  .content-wrapper-odd {
    @apply   mx-auto px-5 w-fit text-gray-300 text-left justify-evenly;
    text-shadow: -18px -18px -18px rgb(16, 16, 16, 0.5);
  }

  .exp-tree {
    white-space: nowrap;
  }
  
  .normal-gray-text {
    @apply text-gray-300;
    text-shadow: -18px -18px -18px rgb(16, 16, 16, 0.5);
  }

  .exp-box:hover {
    background-color: rgba(255, 255, 0, 0.1);
    box-shadow: 0 8px 16px rgba(255, 255, 0, 0.7); /* Same shadow effect */
    transition: background-color 0.3s ease, box-shadow 0.3s ease, background-color 1 ease; /* Smooth transition */
  }

  .box {
    border: 1px solid #ddd; /* Light gray border */
    padding: 20px;
    border-radius: 5px; /* Optional: for rounded corners */
    transition: box-shadow 0.3s ease; /* Smooth transition for the shadow */
  }
  
  .box:hover {
    box-shadow: 0 8px 16px rgb(7, 125, 68); /* Shadow effect on hover */
  }
  
  .text-wrap {
    white-space: normal !important; /* Wrap text */
    padding-left: -100px !important;
  }

  .edu-box {
    border: 1px solid #ddd; /* Light gray border */
    padding: 20px;
    border-radius: 5px; /* Optional: for rounded corners */
    transition: box-shadow 0.3s ease; /* Smooth transition for the shadow */
  }

  .box-experience-blue {
    border: 1px solid #ddd; /* Light gray border */
    padding: 20px;
    border-radius: 5px; /* Optional: for rounded corners */
    transition: box-shadow 0.3s ease; /* Smooth transition for the shadow */
  }
  
  .box-experience-blue:hover {
    box-shadow: 0 8px 16px #4f46e5; /* Shadow effect on hover */
  }
  

  /* .box {
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease;
  }
  
  .box::before {
    content: '';
    position: absolute;
    top: var(--y, 50%);
    left: var(--x, 50%);
    width: 300%;
    height: 300%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.25), transparent 70%);
    transform: translate(-50%, -50%);
    transition: transform 0.3s ease;
    pointer-events: none;
  }
  
  .box:hover::before {
    transform: translate(-50%, -50%) scale(1.1);
  } */

  /* .burger-menu {
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 80;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  } */

  .top-navigation {
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    justify-content: flex-start;
    background-color: rgba(0, 0, 0, 1); /* bg-black bg-opacity-90 */
    width: 100vw; /* w-screen */
    height: 4rem; /* h-16 */
    position: fixed; /* fixed position */
    z-index: 600; 
    border-radius: 0; 
    margin: 0 auto;
    border: none;
    transition: all 0.3s ease-in-out;
    padding-left: 6rem; /* md:pl-24 */
    top: 0; /* top-0 */
  }

  /* .top-navigation.sticky {
    width: 80vw;
    display: flex !important;
    flex-direction: row !important;
    border-radius: 12px; 
    border: 0.5px solid rgba(245, 241, 241, 0.904); /* 1px border with reduced opacity */
    /* z-index: 60;
    opacity: 1;
    top: 100; /* Ensure it stays at the top */
    /* background-color: rgba(0, 0, 0, 0.9); 
    margin-bottom: -2000px;
    margin-top: 100px;
  } */

  .blur-effect {
    filter: blur(5px); /* Adjust the blur amount as needed */
    opacity: 0.5; /* Optional: reduce opacity */
    transition: filter 0.3s ease, opacity 0.3s ease; /* Smooth transition */
  }

  .top-navigation-icon {
    @apply hover:animate-pulse hover:text-white;
  }

  .top-navigation-icon:first {
    @apply ml-auto mr-4;
  }

  .title-hashtag {
    @apply md:text-xl text-sm tracking-wider text-gray-700
    ml-12 my-auto md:ml-20 ;
  }

  .title-text {
    @apply md:text-xl text-sm md:font-bold text-gray-700 tracking-wider 
    mr-auto ml-8 md:ml-16 my-auto  
    transition duration-300 ease-in-out ;
  }

  .search {
    @apply w-1/5 flex items-center justify-start 
    bg-gray-400
    text-gray-500
    px-2 h-9 ml-0 mr-0 
    rounded-md shadow-md
    transition duration-300 ease-in-out;
  }

  .search-input {
    @apply w-full  font-semibold
    bg-transparent outline-none 
    text-gray-500  placeholder-gray-500
    pl-1 rounded;
  }

  /* .vertical-line  {
    @apply border-double border-l-4 border-gray-600 h-full left-32 md:left-96 md:pl-10 absolute mt-14;
  } */

  .exp-img {
    @apply  w-12 h-12 -ml-3 mt-3 md:mr-40 md:ml-32 md:mt-4 md:w-20 z-10;
  }

  .job-tooltip {
    @apply absolute w-auto p-2 m-2 min-w-max left-20 rounded-md shadow-md text-white bg-gray-600 text-xs font-bold transition-all duration-100 scale-0 origin-left ;
  }
  
  .description-tooltip {
    @apply absolute w-auto p-2 m-2 min-w-max left-48 rounded-md shadow-md text-white bg-gray-600 text-xs font-bold transition-all duration-100 scale-0 origin-left md:ml-80 ;
  }

  .degree-border { 
    @apply border border-transparent px-4 py-4 mb-16 md:ml-52 md:mt-10 rounded-xl;
  }

  .footer {
    @apply pb-1 pr-2 text-right bg-gray-300 bg-opacity-30
     /* ml-20 md:ml-20  */
     md:pr-10 justify-evenly;
  }

  .sidebar-hr {
    @apply w-52 text-center justify-center ml-96 pl-96 mt-2 
  }

  .mockup-code {
      @apply min-w-[18rem] bg-neutral py-5 text-neutral-content rounded-box;

  }
  .mockup-code::before{
    content: "";
    @apply mb-4 block h-3 w-3 rounded-full opacity-40;
    box-shadow: 1.4em 0 red, 2.8em 0 yellow, 4.2em 0 green ;
}
  .beauty-text {
    /* font-family: "Brush Script MT", ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; */
    @apply  font-normal tracking-tight;
  }

  /* .blue__gradient {
    background: linear-gradient(180deg, rgba(188, 165, 255, 0) 0%, #214d76 100%);
    filter: blur(123px);
  }
  
  .pink__gradient {
    background: linear-gradient(90deg, #f4c4f3 0%, #fc67fa 100%);
    filter: blur(900px);
  }
  
  .white__gradient {
    background: rgba(255, 255, 255, 0.6);
    filter: blur(750px);
  } */

  /* .logo-style img {
    margin-right: 10px;
    width: 10%;
    aspect-ratio: 3/2;
    object-fit: contain;
    mix-blend-mode: color-burn;
  } */

  @keyframes fly-up {
    0% {
        top: 100%;
    }
    100% {
        top: 50%;
    }
  }

  .animate-fly-up {
      animation: fly-up 1s forwards;
  }

  @keyframes drop {
    0% {
        top: 0;
    }
    100% {
        top: 50%;
    }
  }

  .animate-drop {
      animation: drop 1s forwards;
  }

  .relative .bg-gradient-to-b {
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  }

  /* Define the keyframes for the fly-in and fly-out animations */
  @keyframes fly-from-right {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes fly-to-right {
    from {
      transform: translateX(0);
      opacity: 1;
    }
    to {
      transform: translateX(100%);
      opacity: 0;
    }
  }

  /* Initial state of the element */
  .special-fly-from-right {
    transform: translateX(100%);
    opacity: 0;
    transition: transform 1s ease-in-out, opacity 1s ease-in-out;
  }

  /* Apply the animations to the element */
  .special-fly-from-right.in-view {
    animation: fly-from-right 1s ease-in-out forwards;
  }

  .special-fly-from-right.out-of-view {
    animation: fly-to-right 1s ease-in-out forwards;
  }

  /* Initial state of the element */
  .special-fly-from-right-2 {
    transform: translateX(100%);
    opacity: 0;
    transition: transform 1s ease-in-out, opacity 1s ease-in-out;
  }

  /* Apply the animations to the element */
  .special-fly-from-right-2.in-view {
    animation: fly-from-right 1s ease-in-out forwards;
  }

  .special-fly-from-right-2.out-of-view {
    animation: fly-to-right 1s ease-in-out forwards;
  }

  /* Define the keyframes for the fly-in and fly-out animations */
  @keyframes fly-from-bottom {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes fly-to-bottom {
    from {
      transform: translateY(0);
      opacity: 1;
    }
    to {
      transform: translateY(100%);
      opacity: 0;
    }
  }

  /* Initial state of the element */
  .special-fly-from-bottom {
    transform: translateY(100%);
    opacity: 0;
    transition: transform 10s ease-in-out, opacity 1s ease-in-out;
  }

  /* Apply the animations to the element */
  .special-fly-from-bottom.in-view {
    animation: fly-from-bottom 1s ease-in-out forwards;
  }

  .special-fly-from-bottom.out-of-view {
    animation: fly-to-bottom 1s ease-in-out forwards;
  }

  @keyframes fly-from-bottom-2 {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes fly-to-bottom-2 {
    from {
      transform: translateY(0);
      opacity: 1;
    }
    to {
      transform: translateY(100%);
      opacity: 0;
    }
  }
  
  /* Initial state of the element */
  .special-fly-from-bottom-2 {
    transform: translateY(100%);
    opacity: 0;
    transition: transform 10s ease-in-out, opacity 1s ease-in-out;
  }

  /* Apply the animations to the element */
  .special-fly-from-bottom-2.in-view {
    animation: fly-from-bottom-2 1s ease-in-out forwards;
  }

  .special-fly-from-bottom-2.out-of-view {
    animation: fly-to-bottom-2 1s ease-in-out forwards;
  }
  
  @keyframes fly-from-left {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes fly-to-left {
    from {
      transform: translateX(0);
      opacity: 1;
    }
    to {
      transform: translateX(-100%);
      opacity: 0;
    }
  }
  
  .special-fly-from-left {
    transform: translateX(-100%);
    opacity: 0;
    transition: transform 1s ease-in-out, opacity 1s ease-in-out;
  }
  
  .special-fly-from-left.in-view {
    animation: fly-from-left 1s ease-in-out forwards;
  }
  
  .special-fly-from-left.out-of-view {
    animation: fly-to-left 1s ease-in-out forwards;
  }

  .fade-in-section {
    opacity: 0;
    transform: translateY(10vh);
    visibility: hidden;
    transition: opacity 0.6s ease-out, transform 1s ease-out;
    will-change: opacity, visibility;
  }
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }

}
